<template>
  <div id="app" :class="data && (data.themeType=='Tuktuk' || data.themeType=='TuktukStep1') ? 'app--tuktuk' : ''">
    <div :class="{'device-ios' : isIOS }" v-if="data">
      <div v-if="settingParsed.type == 'popup'">
        <div
          @click="openPopup"
          class="px-2 py-1 text-white text-cente pointer embed-openPopupBtn"
          :style="'background-color:' + settingParsed.popupButtonColor"
        >
          <div
            class="flex items-center justify-center"
            style="font-size: 20px; font-weight: 500 !important"
          >
            {{
              settingParsed.popupButtonText
                ? settingParsed.popupButtonText
                : "Klik untuk pemesanan"
            }}
          </div>
        </div>
        <vs-dialog class="popup-form" v-model="popup" width="540px">
          <Form
            :popup="true"
            :data="data"
            :user="user"
            :belongs_to="belongs_to"
            :checkout_id="checkout_id"
            :checkout_data="checkout_data"
            :domain="domain"
            :customWidth="customWidth"
            :form="form"
            :refid="ref"
            :embed="embed"
            :affiliateId="affiliateId" 
            :shippingCourierTypes ="shippingCourierTypes"
            :shippingCourierItems ="shippingCourierItems"
            :setting ="settingParsed"
          ></Form>
        </vs-dialog>
      </div>
      <!-- ${ data.backgroundColor} -->

      <div v-else
      :class="{ 'noBackground': settingParsed.isHideBackground=='true', 'noMarginParent': settingParsed.isNoMargin=='true' }"        
        :style="`background-color: ${!embed && data && data.backgroundColor ? data.backgroundColor : 'transparent'}; min-height: ${embed ? 'unset' :'100vh'};`">
        <Form :popup="false" :data="data" :user="user" :belongs_to="belongs_to" :checkout_id="checkout_id"
          :checkout_data="checkout_data" :domain="domain" :mobileVersion="mobileVersion" :form="form" :refid="ref" :affiliateId="affiliateId" :embed="embed" :shippingCourierTypes="shippingCourierTypes" :shippingCourierItems="shippingCourierItems"  :setting ="settingParsed">
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
// import { defineAsyncComponent } from 'vue'
// const Form = defineAsyncComponent(() => import("../components/Form"))
import Form from '@/components/Form';
import Cookies from "js-cookie";
import config from "@/config.js";
import axios from "axios";
import {getfbpc} from "../helpers/getfbpc";

export default {
  name: "Checkout",
  props: [
    "prop_id",
    "prop_url",
    "prop_domain",
    "prop_settings",
    "prop_form",
    "prop_belongs_to",
    "prop_checkout_id",
    "prop_embed",
    "prop_ref",
  ],
  data() {
    return {
      baseUrl: config.api.replace("/api/", ""),
      data: null,      
      user: null,
      affiliateId: null,
      popup: false,
      popExecuted: false,
      checkout_data: {},
      customWidth: window.innerWidth,
      mobileVersion: false,
      bodyColor: "",
      id: !this.prop_embed ? this.$route.params.id : this.prop_id,
      url: !this.prop_embed ? this.$route.params.url : this.prop_url,
      domain: !this.prop_embed ? window.location.host : this.prop_domain,
      settings: !this.prop_embed
        ? this.$route.query.settings
        : this.prop_settings,
      form: !this.prop_embed ? this.$route.query.form : this.prop_form,
      belongs_to: !this.prop_embed
        ? this.$route.query.belongs_to
        : this.prop_belongs_to,
      checkout_id: !this.prop_embed
        ? this.$route.query.checkout_id
        : this.prop_checkout_id,
      ref: !this.prop_ref
        ? this.$route.query.ref
        : this.prop_ref,
      embed: this.prop_embed,
      shippingCourierTypes:[],
      shippingCourierItems:{},
      getfbpc,
      isIOS:false
    };
  },
  async beforeCreate() {
    this.customWidth = window.innerWidth;
  },
  ready: function () {
    window.addEventListener("resize", this.updateWidth);
  },
  created() {   
    this.isIOS= /iPhone|iPad|iPod/i.test(navigator.userAgent);  
    this.affiliateId = sessionStorage.getItem("affiliateId");
    this.getData();
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
    if (!this.embed) {
      this.bodyColor = document.getElementsByTagName("BODY");
      let self = this;
      setTimeout(() => {
        if (self.data && !self.settingParsed.isHideBackground) {
          document
            .querySelectorAll('meta[name="theme-color"]')
            .forEach(function (x) {
              x.setAttribute("content", self.data.backgroundColor);
            });
          document
            .querySelector('meta[name="apple-mobile-web-app-status-bar-style"]')
            .setAttribute("content", self.data.backgroundColor);
          if (self.bodyColor.length) {
            self.bodyColor[0].style.backgroundColor = self.data.backgroundColor;
          }
        }
      }, 100);
    }
  },
  methods: {
    getTitle() {
      if (this.data) {
        document.title =
          (this.data ? this.data.name : "embed-form") + " - Formulir";
      }
    },
    updateWidth() {
      this.customWidth = window.innerWidth;
      if (this.customWidth <= 560) {
        this.mobileVersion = true;
      } else {
        this.mobileVersion = false;
      }
    },
    openPopup() {
      this.popup = true;
    },
    async getData() {
      let {fbp ,fbc} = this.getfbpc();
      if (this.url && this.domain) {
        let res = await axios.get(config.api + "product/form", {
          params: {
            track: true,
            addAffiliate: this.ref ? ((!this.affiliateId || this.affiliateId == null || this.affiliateId == undefined || this.affiliateId == "undefined")  ? true: false) : true,
            refId: this.ref,
            url: this.url,
            domain: this.domain,
            form: this.form,
            ip: "IP address tracking is disabled",
            timestamp: new Date().getTime(),
            fbp,
            fbc
          },
        });
        if (res.data.success) {
          this.data = res.data.product;
          this.data['nuxt2']=false;
          // check showProduct exist   
          if(this.data.fieldsPositionMobile.findIndex(x=>x.buttonVar=='showPayment')==-1){
            this.data.showPayment=true;
          }

          this.user = res.data.user;
          if(!("TuktukSettingsRedirect" in  this.data.tuktukThemeSetting)){
            this.data.tuktukThemeSetting.TuktukSettingsRedirect=true;
          }  
          if (this.data.shippingDiscount && !("shippingCOD" in this.data)) {
            this.data.shippingCOD = {
                    "shippingType": this.data.shippingType,
                    "shippingServices": this.data.shippingServices,
                    "additionalCost":this.data.additionalCost,
                    "flatShippingPrice":this.data.flatShippingPrice,
                    "jne": this.data.jne,
                    "siCepat": this.data.siCepat,
                    "sap": this.data.sap,
                    "iDexpress":this.data.iDexpress,
                    "jNt":this.data.jNt,
                    "shippingDiscount": this.data.shippingDiscount,
                    "hideAdditionalCost": this.data.hideAdditionalCost,
                };
            
          }  
          this.shippingCourierTypes = res.data.shippingCourierTypes
          this.shippingCourierItems = res.data.shippingCourierItems;
          //ON load remove old cart summary
          Cookies.remove("cartSummaryCheckout");
          if(!("TuktukSettingsAddTOCart" in  this.data.tuktukThemeSetting) || this.data.tuktukThemeSetting.TuktukSettingsAddTOCart==null){
            this.data.tuktukThemeSetting.TuktukSettingsAddTOCart=true;
          }  
          if (Cookies.get("tuktukSummaryCheckout")) {
            this.storeData = JSON.parse(Cookies.get("tuktukSummaryCheckout"));
            if(this.storeData.url!=this.data.url || !("step" in this.$route.query)){           
              Cookies.remove("tuktukSummaryCheckout"); 
              if(this.$route.query.step && this.$route.query.step==2){
                this.$router.replace({ name: "App", params: {url:this.data.url} }); 
              }
            }         
         }       

          this.affiliateId = res.data.affiliateId;
          if (!sessionStorage.getItem("affiliateId")) {
            sessionStorage.setItem("affiliateId", res.data.affiliateId);
          }
          if (!sessionStorage.getItem("compareTime")) {
            sessionStorage.setItem("compareTime", res.data.date);
          }
          this.getTitle();
        }
      } else {
        let res = await axios.get(config.api + "product/form", {
          params: {
            track: true,
            addAffiliate: this.ref ? ((!this.affiliateId || this.affiliateId == null || this.affiliateId == undefined || this.affiliateId == "undefined") ? true : false) : true,
            refId: this.ref,
            id: this.id,
            form: this.form,
            ip: "IP address tracking is disabled",
            timestamp: new Date().getTime(),
            fbp,
            fbc
          },
        });
        if (res.data.success) {
          Cookies.remove("cartSummaryCheckout");
          this.data = res.data.product;
          this.data['nuxt2']=false;
          this.user = res.data.user;
          if(!("TuktukSettingsAddTOCart" in  this.data.tuktukThemeSetting) || this.data.tuktukThemeSetting.TuktukSettingsAddTOCart==null){
            this.data.tuktukThemeSetting.TuktukSettingsAddTOCart=true;
          } 
          if(!("TuktukSettingsRedirect" in  this.data.tuktukThemeSetting)){
            this.data.tuktukThemeSetting.TuktukSettingsRedirect=false;
          }  
          this.shippingCourierTypes = res.data.shippingCourierTypes
          this.shippingCourierItems = res.data.shippingCourierItems;
          if (Cookies.get("tuktukSummaryCheckout")) {
            this.storeData = JSON.parse(Cookies.get("tuktukSummaryCheckout"));
            if(this.storeData.url!=this.data.url || !("step" in this.$route.query)){             
              Cookies.remove("tuktukSummaryCheckout"); 
              if(this.$route.query.step && this.$route.query.step==2){
                this.$router.replace({ name: "App", params: {url:this.data.url} }); 
              }
              
            }                 
         }    
          this.affiliateId = res.data.affiliateId;
          if (!sessionStorage.getItem("affiliateId")) {
            sessionStorage.setItem("affiliateId", res.data.affiliateId);
          }
        }
        
      }
      if (this.checkout_id) {
        let tempData = await axios.get(
          config.api + "order/checkout/" + this.checkout_id
        );
        if (tempData.data.data) {
          this.checkout_data = tempData.data.data;
          if (this.checkout_data && this.checkout_data.form_id) {
            this.form = this.checkout_data.form_id;
          }
        }
      }
    },
  },
  computed: {
    settingParsed() {
      return this.settings ? JSON.parse(this.settings) : {};

    },
  }, 
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getTitle();
      },
    },
  },
  components: {
    Form,
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap');

#app {
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.popup-form .vs-dialog__content {
  padding: 0 !important;
}

.embed-openPopupBtn {
  max-height: 55px;
  border-radius: 8px;
  padding: 13px;
  width: 100%;
  margin: auto;

  @media (max-width: 575px) {
    max-width: 65%;
  }

  @media (min-width: 576px) {
    max-width: 30%;
  }
}
.device-ios{
  input,
  textarea {
    font-size: 16px !important;
    
    &::placeholder {
      font-size: 14px;
    }
  }
}

</style>
