<script>
import {debounce} from 'lodash'
import moment from 'moment'
import axios from 'axios'
import config from '@/config.js'
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },
  props: ['data'],
  data() {
    return {
      coupon: '',
      showCoupons: false,
      couponPaging: {
        size: 20,
        page: 1      
      },
      couponTotal:0,
      couponCount:0,
      coupons:[],
      moment    
    }
  },
  methods: {
    clearDiscountCode(){
      this.$emit('clearDiscountCode')
    },
    selectDiscount(discount){      
      this.coupon='';
      this.$emit('selectDiscount',discount)     
      this.showCoupons=false
    },    
   
    checkdisabled(discount) {            
      let discount_amount=0;        
      let price =  this.data.price;  
      let totalQuantity=this.data.totalQuantity;

      if(this.data.cartSummary.length > 0){
          totalQuantity=0;
          price = 0;
          this.data.cartSummary.map(async (e) => {      
            totalQuantity = totalQuantity + e.qty;     
            let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
              if (e.whosaleData) {
                e.whosaleData.map((one) => {
                  if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
                    subPrice = one.price * parseInt(e.qty);
                  }               
                });
              }             
              price = price + subPrice;     
           
        });
      }      
     if(discount.minimum_requirements.type=='minimum_purchase'){         
        if(Number(discount.minimum_requirements.value)>this.data.totalPrice){
          return discount_amount > 0 
        }        
      }else if(discount.minimum_requirements.type=='minimum_quantity'){       
        if(Number(discount.minimum_requirements.value)>totalQuantity){
          return discount_amount > 0 
        }        
      } 
      if(discount.discount_type=="moneyOffProduct"){


          if(discount.discount_value.type=="fixed"){                   
            discount_amount=(Number(discount.discount_value.value));           
          }else{
            discount_amount =(price *Number(discount.discount_value.value)) / 100;                                        
                           
          }       
          
        }else if(discount.discount_type=="moneyOffOrder"){
          if(discount.discount_value.type=="fixed"){                   
            discount_amount=Number(discount.discount_value.value);         
          }else{
            discount_amount =(this.data.totalPrice *Number(discount.discount_value.value)) / 100;                             
                    
          }  
        }   
    
        return discount_amount > 0        
    },
    getListsPosition() {
      const rect = this.$refs.input.getBoundingClientRect()
      const isOverflow = window.innerHeight - rect.bottom < 400
      const top = isOverflow ? (rect.bottom || 0) - (320 + 38) : (rect.bottom || 0)

      if (isOverflow) {
        this.$refs['dropdown'].setAttribute("data-position", "top");
      } else {
        this.$refs['dropdown'].removeAttribute("data-position");
      }
      
      return {
        left: `${rect.x ? rect.x:  0}px`,
        top: `${top}px`,
        width: `${rect.width ? rect.width: 300 }px`,
      }
    },
    updateDropdownPosition() {
      const position = this.getListsPosition()
      this.$refs['dropdown'].style.transform = `translate(${position.left}, ${position.top})`
      this.$refs['dropdown'].style.width = position.width
    },
    openCoupons(e) {
      this.updateDropdownPosition()
      if (e.target.value.length > 1) {
        this.showCoupons = true
      }
    },
    close() {
      this.showCoupons = false
    },
    onClickDropdown(e) {
      if (e.target.classList.contains('coupon-entry__dropdown')) {
        this.close()
      }
    },
    updateDiscountLists(discounts,replace){   
      this.$emit('updateDiscountLists',discounts,replace)
    },
  },
  mounted() {
    this.updateDropdownPosition()
    window.addEventListener('scroll', () => {
      if (this.$refs['dropdown']) {
        this.updateDropdownPosition()
      }
    })
    window.addEventListener('resize', () => {
      if (this.$refs['dropdown']) {
        this.updateDropdownPosition()
      }
    })
    const self=this;
    this.handleAutofill = debounce(async function () {
      const search=self.coupon.length > 3 ? self.coupon :'';       
      const response = await axios.get(config.api +"product/discountList/"+this.data._id+"?offset="+new Date().getTimezoneOffset()+"&user_id="+this.data.user_id+"&search="+search+"&size="+this.couponPaging.size+"&page="+this.couponPaging.page);      
      if (response.data.success && response.data.data && response.data.data.length > 0) {
        const discountLists = response.data.data.filter(d=>d.discount_value.applies_to.type=='spcific_products' && d.discount_value.applies_to.products.length > 0 ? d.discount_value.applies_to.products.indexOf(this.data._id) > -1 : true);      
        this.coupons=discountLists;          
        this.couponTotal=response.data.count
        this.couponCount=response.data.count  > this.couponPaging.size ? Math.ceil(response.data.count/this.couponPaging.size) : 1  
        this.updateDiscountLists(discountLists,false) 
        
      }else{
        this.coupons=[];
        this.couponTotal=0
        this.couponCount=0
      }   
    }, 500);
    this.handleAutofill('')
  },
  destroyed() {
    window.removeEventListener('scroll', () => {
      if (this.$refs['dropdown']) {
        this.updateDropdownPosition()
      }
    })
    window.removeEventListener('resize', () => {
      if (this.$refs['dropdown']) {
        this.updateDropdownPosition()
      }
    })
  },
  watch: {
    coupon(value) {
      if (value.length > 3) {
        this.showCoupons = true
        this.handleAutofill(value)
      }else{
        this.handleAutofill('')
      }
    },    
  }
}
</script>

<template>
  <div class="coupon-entry">
    <label>{{$t('coupon.EnterCouponCode')}}</label>
    <!-- Dropdown -->
    <div v-click-outside="close">
      <input v-model="coupon" @focus="openCoupons" type="text" ref="input" :placeholder="$t('coupon.entercouponhere')" />
      <div v-show="showCoupons" ref="dropdown" class="coupon-entry__dropdown" @click="onClickDropdown">
        <div class="coupon-entry__lists">
          <template v-if="coupons.length>0">
            <label class="coupon-entry__item" :data-selected="data.payload.discount.code==coupon.title" :key="`coupon-${index}`" v-for="coupon,index in coupons" >
          
              <input :class="{'disabled':!checkdisabled(coupon)}" :disabled="!checkdisabled(coupon)" @click="checkdisabled(coupon) && selectDiscount(coupon)" :value="coupon.title" name="coupon" type="radio" :checked="data.payload.discount.code==coupon.title" />
              <div>
                <p class="coupon-entry__item__name"> {{coupon.title}}</p>
                <template v-if="coupon.minimum_requirements.type == 'minimum_purchase'">
                <p class="coupon-entry__item__description">{{ coupon.minimum_requirements.value ? `Minimum purchase of RP
                  ${Number(coupon.minimum_requirements.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :
                  $t('coupon.NoMinPurchaseRequirement') }}</p>
              </template>
              <template v-else-if="coupon.minimum_requirements.type == 'minimum_quantity'">
                <p class="coupon-entry__item__description">{{ coupon.minimum_requirements.value ? `Minimum purchase of
                  ${coupon.minimum_requirements.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} items` : $t('coupon.NoMinPurchaseRequirement') }}</p>

                </template>
                <template v-else>
                <p class="coupon-entry__item__description">{{ $t('coupon.NoMinPurchaseRequirement') }}</p>
              </template>
              <p class="coupon-entry__item__description" v-if="coupon.end_date">                   
                {{ coupon.end_date ? moment(coupon.full_end_date).diff(moment(coupon.full_start_date), 'days')  > 0 ? "Until "+moment(coupon.full_end_date).diff(moment(coupon.full_start_date), 'days')+" days" : 'Until today' : '' }}
              </p>
                <template v-if="coupon.discount_value.type === 'fixed'">
                  <p class="coupon-entry__item__discount">{{$t('coupon.Discount')}} {{ 'RP' +Number(coupon.discount_value.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</p>

                </template>
                <template v-else>
                  <p class="coupon-entry__item__discount">{{$t('coupon.Discount')}} {{ coupon.discount_value.value ? coupon.discount_value.value + "% " : "" }}
                  </p>
                </template>   
              </div>
              <img src="@/assets/images/illustrations/icon-illustration-coupon-item-normal.svg" alt="O" height="40px" width="40px">
            </label>
          </template>   
          <div  v-else class="flex flex-column items-center justify-center">
            <img src="@/assets/images/empty-state/coupons-empty-red.svg" width="56px" height="56px" alt="Empty" style="margin-bottom: 8px;">
            <p class="color-mute m-0" style="font-size: 14px; font-weight: 500; font-family: TikTokDisplayMedium;">
              {{$t('coupon.Couponnotfound')}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Selected Coupons -->
    <div class="coupon-entry__selected"  v-if="(data.payload.automaticDiscount.code && data.payload.automaticDiscount.amount > 0) || (data.payload.discount.code && data.payload.discount.amount > 0)">
      <p class="m-10" style="color: #15171C;">{{$t('coupon.Usedcoupons')}}</p>
      <template v-if="data.payload.automaticDiscount.code">
        <div class="flex items-start" style="gap: 8px;">
          <img src="@/assets/icons/TuktukTheme/checklist--pink.svg" alt="0">
          <div>
            <p class="coupon-entry__selected__name">
              {{ data.payload.automaticDiscount.code }}
              <span>(Kupon Otomatis)</span>
            </p>
            <template v-if="data.payload.automaticDiscount.discount_value.type === 'fixed'">
              <p class="coupon-entry__selected__discount">{{$t('coupon.Discount')}} {{ 'RP' +Number(data.payload.automaticDiscount.discount_value.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</p>

            </template>
            <template v-else>
              <p class="coupon-entry__selected__discount">{{$t('coupon.Discount')}} {{ data.payload.automaticDiscount.discount_value.value ? data.payload.automaticDiscount.discount_value.value + "% " : "" }}
              </p>
            </template>   
            <template v-if="data.payload.automaticDiscount.minimum_requirements.type == 'minimum_purchase'">
              <p  class="coupon-entry__selected__description">{{ data.payload.automaticDiscount.minimum_requirements.value ? `Minimum purchase of RP
                ${Number(data.payload.automaticDiscount.minimum_requirements.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :
                $t('coupon.NoMinPurchaseRequirement') }}</p>
            </template>
            <template v-else-if="data.payload.automaticDiscount.minimum_requirements.type == 'minimum_quantity'">
              <p  class="coupon-entry__selected__description">{{ data.payload.automaticDiscount.minimum_requirements.value ? `Minimum purchase of
                ${data.payload.automaticDiscount.minimum_requirements.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} items` : $t('coupon.NoMinPurchaseRequirement') }}</p>

              </template>
              <template v-else>
              <p  class="coupon-entry__selected__description">{{ $t('coupon.NoMinPurchaseRequirement') }}</p>
            </template>
            <p class="coupon-entry__selected__description" v-if="data.payload.automaticDiscount.end_date">                   
              {{ data.payload.automaticDiscount.end_date ? moment(data.payload.automaticDiscount.full_end_date).diff(moment(data.payload.automaticDiscount.full_start_date), 'days')  > 0 ? "Until "+moment(data.payload.automaticDiscount.full_end_date).diff(moment(data.payload.automaticDiscount.full_start_date), 'days')+" days" : 'Until today' : '' }}
            </p>
          </div>          
        </div>     
      </template>
      <template v-if="data.payload.discount.code && data.payload.discount.amount > 0">
        <div class="flex items-start" style="gap: 8px;">
          <img src="@/assets/icons/TuktukTheme/checklist--pink.svg" alt="0">
          <div style="flex-grow: 1;">
            <p class="coupon-entry__selected__name">{{ data.payload.discount.code }}</p>
            <template v-if="data.payload.discount.discount_value.type === 'fixed'">
              <p class="coupon-entry__selected__discount">{{$t('coupon.Discount')}} {{ 'RP' +Number(data.payload.discount.discount_value.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</p>

            </template>
            <template v-else>
              <p class="coupon-entry__selected__discount">{{$t('coupon.Discount')}} {{ data.payload.discount.discount_value.value ? data.payload.discount.discount_value.value + "% " : "" }}
              </p>
            </template>   
            <template v-if="data.payload.discount.minimum_requirements.type == 'minimum_purchase'">
              <p  class="coupon-entry__selected__description">{{ data.payload.discount.minimum_requirements.value ? `Minimum purchase of RP
                ${Number(data.payload.discount.minimum_requirements.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :
                $t('coupon.NoMinPurchaseRequirement') }}</p>
            </template>
            <template v-else-if="data.payload.discount.minimum_requirements.type == 'minimum_quantity'">
              <p  class="coupon-entry__selected__description">{{ data.payload.discount.minimum_requirements.value ? `Minimum purchase of
                ${data.payload.discount.minimum_requirements.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} items` : $t('coupon.NoMinPurchaseRequirement') }}</p>

              </template>
              <template v-else>
              <p  class="coupon-entry__selected__description">{{ $t('coupon.NoMinPurchaseRequirement') }}</p>
            </template>
            <p class="coupon-entry__selected__description" v-if="data.payload.discount.end_date">                   
              {{ data.payload.discount.end_date ? moment(data.payload.discount.full_end_date).diff(moment(data.payload.discount.full_start_date), 'days')  > 0 ? "Until "+moment(data.payload.discount.full_end_date).diff(moment(data.payload.discount.full_start_date), 'days')+" days" : 'Until today' : '' }}
            </p>
          </div>
          <img  @click="clearDiscountCode" role="button" src="@/assets/icons/ic-close.svg" alt="x">
        </div>     
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.coupon-entry {
  padding: 0 15px 8px;
  label {
    color: #203551;
    font-family: TikTokDisplayBold;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 6px !important;
  }
  input {
    font-size: 16px !important;
  }
  input[type=text] {
    width: 100%;
    height: 38px;
    padding: 10px 12px;
    border: 1px solid #E0E9F4;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px #1018280D;
  }

  &__dropdown {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    height: 320px;

    &[data-position=top] {
      display: flex;
      flex-direction: column;
      justify-content: end;
    }
  }

  &__lists {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    max-height: 320px;
    overflow-y: auto;
    background: #FFF;
    border-radius: 10px;
    box-shadow: 0px 5px 20px 0px #2035511A;
  }

  &__selected {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 8px 10px;
    margin-top: 16px;
    background: #EB435B0D;
    border: 1px solid #EB435B26;
    border-radius: 4px;

    p {
      margin: 0;
    }

    &__name,
    &__name span {
      color: #203551;
      font-weight: 500;
      font-size: 12px;
      font-family: TikTokDisplayMedium;
      line-height: 18px;
    }

    &__name span {
      color: #6C7E95;
    }

    &__discount {
      color: #EB435B;
      font-weight: 700;
      font-size: 12px;
      font-family: TikTokDisplayMedium;
      line-height: 18px;
    }

    &__description {
      opacity: 0.4;
      color: #15171C;
      font-weight: 400;
      font-size: 12px;
      font-family: TikTokDisplayRegular;
      line-height: 18px;
    }

    [role=button] {
      cursor: pointer;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px 8px;
    border: 1px solid #E0E9F4;
    border-radius: 8px;
    cursor: pointer;

    &[data-selected=true] {
      background: #FEEBEB;
      border: 1px solid #F75656;
    }

    &__name {
      color: #EB435B;
      font-weight: 600;
      font-size: 13px;
      font-family: TikTokDisplayMedium;
      line-height: 16px;
    }

    &__discount {
      color: #EB435B;
      font-weight: 400;
      font-size: 13px;
      font-family: TikTokDisplayRegular;
      line-height: 18px;
    }

    &__description {
      color: #6C7E95;
      font-weight: 400;
      font-size: 11px;
      font-family: TikTokDisplayRegular;
      line-height: 16px;
    }
    
    input[type=radio] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #FFF;
      margin: 0;
      font: inherit;
      color: currentColor;
      width: 20px;
      height: 20px;
      border: 1px solid #D5DAF1;
      border-radius: 50%;

      transition: border 50ms ease-out;
      align-self: center;
      display: grid;
      place-content: center;
      cursor: pointer;

      &:checked {
        border: 6px solid #EB435B;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;

        &:not(:checked) {
          background-color: #D5DAF1;
          opacity: 0.4;
        }
      }
    }

    div {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 2px;
      
      p {
        margin: 0;
      }
    }

    img {
      flex-shrink: 0;
    }
  }
}
</style>